import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './Terms.css'; // Updated styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Terms = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    setMenuOpen(false);
    window.location.href = event.target.href;
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      sections.forEach(section => {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (sectionTop < windowHeight - 100) {
          section.classList.add('appear');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="terms-page">
      <header className="terms-header">
        <h1 className="terms-title">Terms and Conditions</h1>
        <nav className="terms-nav">
          <ul className={`terms-nav-list ${menuOpen ? 'active' : ''}`}>
          <li><Link to="/" className={window.location.hash === '#/' ? 'active' : ''}>Home</Link></li>
          <li><Link to="/Mocktest" className={window.location.hash === '#/Mocktest' ? 'active' : ''}>Mocktest</Link></li>

            <li><Link to="/about" className={window.location.hash === '#/about' ? 'active' : ''}>About</Link></li>
            <li><Link to="/terms" className={window.location.hash === '#/terms' ? 'active' : ''}>Terms & Condition</Link></li>
            <li><Link to="/blogs" className={window.location.hash === '#/blogs' ? 'active' : ''}>Blogs</Link></li>
            <li><Link to="/Refund" className={window.location.hash === '#/Refund' ? 'active' : ''}>Return,Refund & Cancellation</Link></li>
            <li><Link to="/Privacy" className={window.location.hash === '#/Privacy' ? 'active' : ''}>Privacy</Link></li>
            <li><Link to="/Contact" className={window.location.hash === '#/Contact' ? 'active' : ''}>Contact</Link></li>

          </ul>

          <div className={`terms-hamburger-menu ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav>
      </header>

      {/* Mobile Menu */}
      <div className={`terms-mobile-menu ${menuOpen ? 'open' : ''}`}>
        <div className="terms-back-button" onClick={() => setMenuOpen(false)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <div className="terms-cut-button" onClick={toggleMenu}>
          <i className="fas fa-times"></i>
        </div>
        <li><Link to="/" onClick={handleLinkClick} className={window.location.hash === '#/' ? 'active' : ''}>Home</Link></li>
        <li><Link to="/Mocktest" onClick={handleLinkClick} className={window.location.hash === '#/Mocktest' ? 'active' : ''}>Mocktest</Link></li>

            <li><Link to="/about" onClick={handleLinkClick} className={window.location.hash === '#/about' ? 'active' : ''}>About</Link></li>
            <li><Link to="/terms" onClick={handleLinkClick} className={window.location.hash === '#/terms' ? 'active' : ''}>Terms</Link></li>
            <li><Link to="/blogs" onClick={handleLinkClick} className={window.location.hash === '#/blogs' ? 'active' : ''}>Blogs</Link></li>
            <li><Link to="/Refund" onClick={handleLinkClick} className={window.location.hash === '#/Refund' ? 'active' : ''}>Refund</Link></li>
            <li><Link to="/Privacy" onClick={handleLinkClick} className={window.location.hash === '#/Privacy' ? 'active' : ''}>Privacy</Link></li>
            <li><Link to="/Contact" onClick={handleLinkClick} className={window.location.hash === '#/Contact' ? 'active' : ''}>Contact</Link></li>
      </div>

      <div className="terms-content-wrapper">
        <main>
        <section>

          <section>
      <h2 className="section-title">1. Introduction</h2>
      <ul className="mission-points">
        <li className="animated-text">Welcome to GuruGuess, an online mock platform that provides mocks based on speed and accuracy of responses.</li>
        <li className="animated-text"> By joining in any mock on GuruGuess, you agree to abide by the following terms and conditions. These terms apply to all users of the platform.</li>
      </ul>
    </section>
    <section>
      <h2 className="section-title">2. Eligibility</h2>
      <ul className="mission-points">
        <li className="animated-text">By entering a mock, you confirm that all registration information you submit is truthful and accurate.</li>
      </ul>
    </section>
    <section>
      <h2 className="section-title">3. Payment and Verification</h2>
      <ul className="mission-points">
        <li className="animated-text">To join  a mock, users must complete the payment process as outlined on the platform. Payment methods accepted include credit/debit cards, digital wallets, and other methods as specified.</li>
        <li className="animated-text">Payment verification is mandatory before users can join any contest. GuruGuess reserves the right to withhold access to mocks until payment is verified.</li>
        <li className="animated-text">Once payment is verified, a confirmation will be sent to the registered email, and the user will be able to join the contest.</li>
      </ul>
    </section>
    <section>
      <h2 className="section-title">4. Mock</h2>
      <ul className="mission-points">
        <li className="animated-text">Mocks on GuruGuess are time-bound </li>
        <li className="animated-text">Users must join the mock on time frame specified on the mock details page. </li>
        <li className="animated-text">Mock timing, including start and end times, will be strictly adhered to, and any technical issues should be reported to customer support immediately.</li>
      </ul>
    </section>
    <section>
      <h2 className="section-title">5. Question Marking and Scoring</h2>
      <ul className="mission-points">
        <li className="animated-text">Mocks scoring is based on both the accuracy of answers and the speed at which they are submitted. Each mock may have different scoring criteria, which will be clearly mentioned before the mock begins.</li>
        <li className="animated-text">Speed of response will be a key factor in determining the final score. Faster correct responses will be awarded more points.</li>
        <li className="animated-text">Any attempt to manipulate the speed of response, such as using bots or automated systems, is strictly prohibited and will result in disqualification and forfeiture of any prizes.</li>
      </ul>
    </section>
    
    <section>
      <h2 className="section-title">6. Cancellation and Refunds</h2>
      <ul className="mission-points">
        <li className="animated-text">Mock joining fees are non-refundable unless the mock is expired by GuruGuess. In such cases, refunds will be processed within a specified time frame.</li>
        <li className="animated-text">GuruGuess reserves the right to cancel or modify any contest at any time without prior notice. In the event of cancellation, entry fees will be refunded.</li>
      </ul>
    </section>
    <section>
      <h2 className="section-title">7. User Conduct</h2>
      <ul className="mission-points">
        <li className="animated-text">Users must engage in mocks and refrain from any activity that may disrupt the mocks or give an unfair advantage.</li>
        <li className="animated-text">Any form of cheating, collusion, or other unethical behavior is prohibited and will result in disqualification.</li>
      </ul>
    </section>
    <section>
      <h2 className="section-title">8. Privacy and Data Protection</h2>
      <ul className="mission-points">
        <li className="animated-text">GuruGuess is committed to protecting user privacy. Personal information collected during registration and mock participation will be used in accordance with our Privacy Policy.</li>
        <li className="animated-text">Payment information will be processed securely and will not be stored on our servers.</li>
      </ul>
    </section>

    <section>
      <h2 className="section-title">9. Limitation of Liability</h2>
      <ul className="mission-points">
        <li className="animated-text">GuruGuess will not be liable for any loss or damage arising from participation in mocks, including but not limited to technical issues, loss of data, or unauthorized access to user accounts.</li>
        <li className="animated-text">Users participate in mocks at their own risk, and GuruGuess makes no warranties or guarantees regarding the accuracy, reliability, or performance of the platform.</li>
      </ul>
    </section>

    <section>
      <h2 className="section-title">10. Amendments to Terms</h2>
      <ul className="mission-points">
        <li className="animated-text">GuruGuess reserves the right to modify these terms and conditions at any time. Users will be notified of any changes, and continued participation in contests will be considered acceptance of the revised terms.</li>
      </ul>
    </section>

    <section>
      <h2 className="section-title">11. Contact Information</h2>
      <ul className="mission-points">
        <li className="animated-text">For any questions or concerns regarding these terms and conditions, please contact our support team at contactus@guruguess.com</li>
        <li className="animated-text">Address: Miyapur, Jaunpur, Uttar Pradesh, India,222002</li>
        <li className="animated-text">POWERED BY: KNKN Engeenering Works pvt. ltd.</li>


      </ul>
    </section>
          </section>
        </main>
      </div>

      <footer className="terms-footer">
        <div className="terms-footer-content">
          <div className="terms-footer-section contact-info">
            <p><FontAwesomeIcon icon={faEnvelope} /> Email: <a href="mailto:guruguesss@gmail.com">guruguesss@gmail.com</a></p>
            <p><FontAwesomeIcon icon={faInstagram} /> Instagram: <a href="https://www.instagram.com/guruguesss" target="_blank" rel="noopener noreferrer">@guruguesss</a></p>
          </div>
          <div className="terms-footer-section">
            <p><FontAwesomeIcon icon={faPhone} /><a href="tel:9140293443">Contact Us 9140293443</a></p>
            <p><FontAwesomeIcon icon={faPhone} /><a href="tel:7460802449">For more queries contact on 7460802449</a></p>
          </div>
        </div>
        <div className="terms-footer-bottom">
        <p>Powered by : KNKN Engeenering Works pvt. ltd.</p>
          <p>© 2024 Guruguess. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Terms;
