import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Model.css';

const Model = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { product } = location.state || {};

  if (!product) {
    return (
      <div>
        <h2>No product selected</h2>
        <p>Please go back and select a product.</p>
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Thank you! Your order has been placed.');
  };

  return (
    <div className="page-container">
      <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      <div className="page-content">
        {/* Left Section - Form */}
        <div className="left-section">
          <h3>Contact</h3>
          <form onSubmit={handleSubmit} className="form-section">
            <label>
              Email or mobile phone number:
              <input type="email" required />
            </label>
            <label>
              <input type="checkbox" /> Email me with news and offers
            </label>
            <h3>Delivery</h3>
            <label>
              Country/Region:
              <select required>
                <option>India</option>
                {/* Add other countries as needed */}
              </select>
            </label>
            <label>
              First name (optional):
              <input type="text" />
            </label>
            <label>
              Last name:
              <input type="text" required />
            </label>
            <label>
              Address:
              <input type="text" required />
            </label>
            <label>
              City:
              <input type="text" required />
            </label>
            <label>
              State:
              <input type="text" required />
            </label>
            <label>
              PIN code:
              <input type="tel" required />
            </label>
            <label>
              Phone Number:
              <input type="tel" required />
            </label>
            <button type="submit" className="paynow-button">Pay Now</button>
          </form>
        </div>

        {/* Right Section - Product Summary */}
        <div className="right-section">
          <img src={product.image} alt={product.name} className="product-image" />
          <h2>{product.name}</h2>
          <div className="price-summary">
  <p>Subtotal (1 item): ₹{parseFloat(product.price).toFixed(2)}</p>
  <p>Estimated taxes: ₹{(parseFloat(product.price) * 0.07).toFixed(2)}</p>
  <h3>Total: ₹{(parseFloat(product.price) + (parseFloat(product.price) * 0.07)).toFixed(2)}</h3>
</div>

        </div>
      </div>
    </div>
  );
};

export default Model;
