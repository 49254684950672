import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import b11 from '../assets/B2.png';
import b22 from '../assets/B3.png';
import b33 from '../assets/b4 (1).png';
import b44 from '../assets/back.jpg';
import b55 from '../assets/jee.png';
import b66 from '../assets/neet.png';
import b77 from '../assets/Group 22.png';
import './Mocktest.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Mocktest = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate(); // Use navigate for programmatic navigation

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleAddToCartClick = (product) => {
    // Navigate to Model page and pass product details
    navigate('/Model', { state: { product } });
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    setMenuOpen(false);
    window.location.href = event.target.href;
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      sections.forEach(section => {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (sectionTop < windowHeight - 100) {
          section.classList.add('appear');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Define products array here
  const products = [
    { id: 1, name: "Jee Mocks", price: "95rs.", image: b55 },
    { id: 2, name: "Neet Mocks", price: "125rs.", image: b66 },
    { id: 3, name: "Android Development", price: "125rs.", image: b77 },
    { id: 4, name: "Data Structure", price: "400rs.", image: b33 },
  ];

  return (
    <div className="about-page">
      <header className="about-header">
        <h1 className="about-title">Mocks</h1>
        <nav className="about-nav">
          <ul className={`about-nav-list ${menuOpen ? 'active' : ''}`}>
            <li><Link to="/" className={window.location.hash === '#/' ? 'active' : ''}>Home</Link></li>
            <li><Link to="/Mocktest" className={window.location.hash === '#/Mocktest' ? 'active' : ''}>Mocktest</Link></li>
            <li><Link to="/about" className={window.location.hash === '#/about' ? 'active' : ''}>About</Link></li>
            <li><Link to="/terms" className={window.location.hash === '#/terms' ? 'active' : ''}>Terms & Condition</Link></li>
            <li><Link to="/blogs" className={window.location.hash === '#/blogs' ? 'active' : ''}>Blogs</Link></li>
            <li><Link to="/Refund" className={window.location.hash === '#/Refund' ? 'active' : ''}>Return,Refund & Cancellation</Link></li>
            <li><Link to="/Privacy" className={window.location.hash === '#/Privacy' ? 'active' : ''}>Privacy</Link></li>
            <li><Link to="/Contact" className={window.location.hash === '#/Contact' ? 'active' : ''}>Contact</Link></li>
          </ul>

          <div className={`about-hamburger-menu ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav>
      </header>

      {/* Mobile Menu */}
      <div className={`about-mobile-menu ${menuOpen ? 'open' : ''}`}>
        <div className="about-back-button" onClick={() => setMenuOpen(false)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <li><Link to="/" onClick={handleLinkClick} className={window.location.hash === '#/' ? 'active' : ''}>Home</Link></li>
        <li><Link to="/Mocktest" onClick={handleLinkClick} className={window.location.hash === '#/Mocktest' ? 'active' : ''}>Mocktest</Link></li>
        <li><Link to="/about" onClick={handleLinkClick} className={window.location.hash === '#/about' ? 'active' : ''}>About</Link></li>
        <li><Link to="/terms" onClick={handleLinkClick} className={window.location.hash === '#/terms' ? 'active' : ''}>Terms</Link></li>
        <li><Link to="/blogs" onClick={handleLinkClick} className={window.location.hash === '#/blogs' ? 'active' : ''}>Blogs</Link></li>
        <li><Link to="/Refund" onClick={handleLinkClick} className={window.location.hash === '#/Refund' ? 'active' : ''}>Refund</Link></li>
        <li><Link to="/Privacy" onClick={handleLinkClick} className={window.location.hash === '#/Privacy' ? 'active' : ''}>Privacy</Link></li>
        <li><Link to="/Contact" onClick={handleLinkClick} className={window.location.hash === '#/Contact' ? 'active' : ''}>Contact</Link></li>
      </div>

      {/* Main Section */}
      <main className="main-content">
        <div className="text-section">
          <h1>Join And Accept Your Field Challenges...</h1>
          <p> The mock page is an interactive platform where users can join scheduled mock tests with the exact test time clearly mentioned in the description.</p>
          <ul className="mission-points">
          <li className="animated-text">Participants will attempt the test within the allocated time frame. Once completed, the system will automatically calculate and display their marks.</li>
        <li className="animated-text">Variety of Topics: We offer mockc on a wide range of subjects, including science, history, sports, entertainment, and more. Each contest is carefully crafted to test your knowledge and speed.</li>
        <li className="animated-text"> This will evaluate their performance and track their progress in real-time.</li>
      </ul>
        </div>
        <div className="image-section">
          <img src={b44} alt="Stack of Books" className="book-image" />
        </div>
      </main>

      {/* Product Section */}
      <section className="product-section">
        <h2>Avaliable Mocks</h2>
        <div className="product-grid">
          {products.map((product) => (
            <div key={product.id} className="product-card">
              <img src={product.image} alt={product.name} className="product-image" />
              <h3>{product.name}</h3>
              <p className="product-price">{product.price}</p>
              <button className="add-button" onClick={() => handleAddToCartClick(product)}>Add to Cart</button>
            </div>
          ))}
        </div>
      </section>

      <footer className="about-footer">
        <div className="about-footer-content">
          <div className="about-footer-section contact-info">
            <p><FontAwesomeIcon icon={faEnvelope} /> Email: <a href="mailto:guruguesss@gmail.com">guruguesss@gmail.com</a></p>
            <p><FontAwesomeIcon icon={faInstagram} /> Instagram: <a href="https://www.instagram.com/guruguesss" target="_blank" rel="noopener noreferrer">@guruguesss</a></p>
          </div>
          <div className="about-footer-section">
            <p><FontAwesomeIcon icon={faPhone} /><a href="tel:9140293443">Contact Us 9140293443</a></p>
            <p><FontAwesomeIcon icon={faPhone} /><a href="tel:7460802449">For more queries contact on 7460802449</a></p>
          </div>
        </div>
        <div className="about-footer-bottom">
       <p> Powered by : KNKN Engeenering Works pvt. ltd.</p>
          <p>© 2024 Guruguess. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Mocktest;
