import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Home.css"; // Import CSS for stylingz
import guru from './assets/Group 505 (1).png';
import { database } from './firebase'; // Make sure to import Firebase
import {getDatabase, ref, onValue, set, get } from 'firebase/database';
import no from './assets/new.png';
import atom from './assets/atom.png';
import enter from './assets/enter.png';
import otp from './assets/otp.png';
import contest from './assets/contest.png';
import pro1 from './assets/person1.png';
import pro2 from './assets/person2.png';
import pro3 from './assets/pro3.png';
import details from './assets/details.png';
import join from './assets/join.png';
import choose from'./assets/join (2).png';
import quizz from './assets/quizz.png';
import wait from './assets/wait.png';
import math from './assets/mathematics.png';
import his from './assets/sphinx.png'
import vote from './assets/voting.png';
import ai from './assets/artificial-intelligence.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { ReactComponent as Flower } from './flower.svg'; 
const Home = () => {
  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [appLink, setAppLink] = useState(""); // State for storing app link
  const [contests, setContests] = useState([]);
  const [loadingContests, setLoadingContests] = useState(true);
  const [headerImage, setHeaderImage] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [userData, setUserData] = useState([]);

  useEffect(() => {

    const fetchContests = async () => {
      const db = getDatabase();
      const contestsRef = ref(db, "Contests"); // Reference to your contests
      const snapshot = await get(contestsRef);
      
      if (snapshot.exists()) {
        setContests(snapshot.val()); // Set your contests state
      } else {
        console.log("No contests found");
      }
    };

    fetchContests();

    
    const fetchImage = async () => {
      const db = getDatabase(); // Get Firebase Realtime Database instance
      let imageRef;

      // Check the screen size
      if (window.innerWidth >= 768) {
        // Large screen, use "Blink"
        imageRef = ref(db, "Banner/Blink");
      } else {
        // Small screen, use "blink_small"
        imageRef = ref(db, "Banner/blink");
      }

      // Fetch the data once from Firebase
      const snapshot = await get(imageRef);
      if (snapshot.exists()) {
        setHeaderImage(snapshot.val()); // Set the fetched image URL to state
      } else {
        console.log("No image found");
      }
    };

    fetchImage();


    const handleScroll = () => {
      setIsHeaderScrolled(window.scrollY > 50);
    };

    const handleClickOutsideMenu = (event) => {
      if (!event.target.closest('.full-page-menu') && !event.target.closest('.hamburger-menu')) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('click', handleClickOutsideMenu);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('click', handleClickOutsideMenu);
    };
  }, []);


  
// Function to show or hide the header popup
const togglePopup = (show) => {
  const popup = document.querySelector('.popup');
  if (show) {
    popup.classList.add('show');
  } else {
    popup.classList.remove('show');
  }
};

// Function to show or hide the error message
const showErrorPopup = (message) => {
  const errorPopup = document.querySelector('.error-message');
  errorPopup.textContent = message;
  errorPopup.classList.add('show');
  setTimeout(() => {
    errorPopup.classList.remove('show');
  }, 2000); // Hide after 2 seconds
};



useEffect(() => {
  const db = getDatabase();
  const mycontestRef = ref(db, 'mycontest');
  const usersRef = ref(db, 'Users');

  // Fetch mycontest data
  onValue(mycontestRef, (snapshot) => {
      const contestData = snapshot.val();
      const amountWonByUid = {};

      // Sum amountWon for each uid
      for (let uid in contestData) {
          let totalAmountWon = 0;
          for (let contestId in contestData[uid]) {
              const amountWon = Number(contestData[uid][contestId].amountWon) || 0;
              totalAmountWon += amountWon;
          }
          amountWonByUid[uid] = totalAmountWon;
      }

      // Fetch Users data and match with uid
      onValue(usersRef, (usersSnapshot) => {
          const usersData = usersSnapshot.val();
          const result = [];

          for (let uid in amountWonByUid) {
              if (usersData[uid]) {
                  const firstName = usersData[uid]?.firstName || 'Unknown';
                  const lastName = usersData[uid]?.lastName || 'Unknown';

                  result.push({
                      firstName,
                      lastName,
                      totalAmountWon: amountWonByUid[uid],
                  });
              }
          }

          // Sort the result by totalAmountWon in decreasing order
          result.sort((a, b) => b.totalAmountWon - a.totalAmountWon);

          // Limit the result to the top 20
          setUserData(result.slice(0, 20));
      });
  });
}, []);

// Split data into top 6 and the rest
const topSix = userData.slice(0, 6);
const remainingUsers = userData.slice(6);





  useEffect(() => {
    const initialPopupTimer = setTimeout(() => {
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000); // Popup disappears after 2 seconds
    }, 500); // Delay before first showing the popup

    const regeneratePopupTimer = setTimeout(() => {
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000); // Popup disappears after 2 seconds
    }, 10500); // Popup reappears after 10 seconds on reload


    
    return () => {
      clearTimeout(initialPopupTimer);
      clearTimeout(regeneratePopupTimer);
    };
  }, []);
  useEffect(() => {
    const contestsRef = ref(database, `Allcontest`);
    const unsubscribe = onValue(contestsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const contestList = Object.keys(data).map((contestId) => ({
          contestId,
          ...data[contestId],
        }));
        setContests(contestList);
      } else {
        console.log("No data available");
      }
      setLoadingContests(false); // Set loading to false after fetching data
    });
    
    return () => unsubscribe();
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSmallMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    setMenuOpen(false);
    setMenuVisible(false);
    window.location.href = event.target.href;
  };

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^(\+91)?\d{10}$/;
        return phoneRegex.test(phone);
  };

  const handleInputChange = (e) => {
    setPhoneNumber(e.target.value);
    setError("");
  };

  const showError = (message) => {
    setError(message);

    // Automatically hide the error message after 2 seconds
    setTimeout(() => {
      setError('');
    }, 2000); // 2 seconds display time
  };
  


  const handleDownloadClick = () => {
    if (!phoneNumber) {
      showError("Please enter your mobile number");
      return;
    }
    if (!isValidPhoneNumber(phoneNumber)) {
      showError("Please enter a valid 10-digit mobile number with +91");
      return;
    }

    const database = getDatabase();

    const phoneRef = ref(database, 'phoneNumbers/' + phoneNumber);
    set(phoneRef, {
      phoneNumber: phoneNumber,
      timestamp: new Date().toISOString(),
    })
    .then(() => {
      // Fetch download link from Firebase
      const linkRef = ref(database, 'Link/link'); // Change this path according to your Firebase structure
      return get(linkRef); // Fetch the download link
    })
    .then((snapshot) => {
      if (snapshot.exists()) {
        const downloadLink = snapshot.val();
        console.log("Download link fetched:", downloadLink);
        window.location.href = downloadLink; // Redirect to the fetched download link
      } else {
        showError("Download link not available");
      }
    })
      .catch((error) => {
        console.error("Error saving phone number:", error);
        showError("Failed to save phone number, try again later");
      });
  };
        
  const handleCardClick = async (contestId) => {
    const db = getDatabase();
    const linkRef = ref(db, `Links/${contestId}`); // Adjust the path according to your structure
    const snapshot = await get(linkRef);
    
    if (snapshot.exists()) {
      const downloadLink = snapshot.val(); // Assuming this returns the download link
      window.location.href = downloadLink; // Start download
    } else {
      console.log("No link found for this contest");
    }
  };




  return (
    <div className="home-container">
      {/* Header Section */}
      <header className={`header ${isHeaderScrolled ? 'scrolled' : ''}`}
      style={{
        backgroundImage: !scrolled && headerImage ? `url(${headerImage})` : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}>
        <div className="logo-container">
          <div className="logo-circle">
            <img src={guru} alt="Logo" className="logo-image" />
          </div>
          <div className="logo-text">GuruGuess</div>
           {/* Popup in the Header */}
           {showPopup && !isHeaderScrolled && (
            <div className="popup">
              <p>SwipeUp ⬆️ </p>
            </div>
          )}
        </div>
        <nav className="desktop-nav">
          <ul className={`nav-list ${menuOpen ? 'open' : ''}`}>
          <li><Link to="/" className={window.location.hash === '#/' ? 'active' : ''}>Home</Link></li>
          <li><Link to="/Mocktest" className={window.location.hash === '#/Mocktest' ? 'active' : ''}>Mocktest</Link></li>
            <li><Link to="/about" className={window.location.hash === '#/about' ? 'active' : ''}>About</Link></li>
            <li><Link to="/terms" className={window.location.hash === '#/terms' ? 'active' : ''}>Terms & Condition</Link></li>
            <li><Link to="/blogs" className={window.location.hash === '#/blogs' ? 'active' : ''}>Blogs</Link></li>
            <li><Link to="/Refund" className={window.location.hash === '#/Refund' ? 'active' : ''}>Return,Refund & Cancellation</Link></li>
            <li><Link to="/Privacy" className={window.location.hash === '#/Privacy' ? 'active' : ''}>Privacy</Link></li>
            <li><Link to="/Contact" className={window.location.hash === '#/Contact' ? 'active' : ''}>Contact</Link></li>


          </ul>
          <div className={`hamburger-menu ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav>
      </header>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="full-page-menu active">
          <button className="close-menu-btn" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <ul className="activeMenu">
          <li><Link to="/" onClick={handleLinkClick} className={window.location.hash === '#/' ? 'active' : ''}>Home</Link></li>
          <li><Link to="/Mocktest" onClick={handleLinkClick} className={window.location.hash === '#/Mocktest' ? 'active' : ''}>Mocktest</Link></li>
            <li><Link to="/about" onClick={handleLinkClick} className={window.location.hash === '#/about' ? 'active' : ''}>About</Link></li>
            <li><Link to="/terms" onClick={handleLinkClick} className={window.location.hash === '#/terms' ? 'active' : ''}>Terms</Link></li>
            <li><Link to="/blogs" onClick={handleLinkClick} className={window.location.hash === '#/blogs' ? 'active' : ''}>Blogs</Link></li>
            <li><Link to="/Refund" onClick={handleLinkClick} className={window.location.hash === '#/Refund' ? 'active' : ''}>Refund</Link></li>
            <li><Link to="/Privacy" onClick={handleLinkClick} className={window.location.hash === '#/Privacy' ? 'active' : ''}>Privacy</Link></li>
            <li><Link to="/Contact" onClick={handleLinkClick} className={window.location.hash === '#/Contact' ? 'active' : ''}>Contact</Link></li>


          </ul>
        </div>
      )}

      {/* Small Screen Menu */}
      <div className="menu">
        <button className="hamburger" onClick={toggleSmallMenu}>☰</button>
        {isMenuVisible && (
          <nav className="nav-components">
            <ul>
            <li><a href="#/" onClick={handleLinkClick}>Home</a></li>
            <li><a href="#/Mocktest" onClick={handleLinkClick}>Mocktest</a></li>
              <li><a href="#/about" onClick={handleLinkClick}>About</a></li>
              <li><a href="#/terms" onClick={handleLinkClick}>Terms</a></li>
              <li><a href="#/blogs" onClick={handleLinkClick}>Blog</a></li>
              <li><a href="#/refund" onClick={handleLinkClick}>Refund</a></li>
              <li><a href="#/Privacy" onClick={handleLinkClick}>Privacy</a></li>
              <li><a href="#/contact" onClick={handleLinkClick}>Contact</a></li>

            </ul>
          </nav>
        )}
      </div>

      {/* Main Section */}
      <main>
        <section className="home-section" id="home-section">
          <div className="hero-content">
            <h1>Turn Your Knowledge</h1>
            <h1> into ₹ewards</h1>

           {/*<h2>into <span className="currency-symbol">₹</span>ewards</h2>*/}
            <div className="download-section">
              <input
                type="text"
                placeholder="+91"
                value={phoneNumber}
                onChange={handleInputChange}
                className="phone-input"
              />
              <button className="download-btn" onClick={handleDownloadClick}>
                Download
              </button>
              {error && <p className={`error-message ${error ? 'show' : 'hide'}`}>{error}</p>}
            </div>
          </div>
          <div className="hero-image">
            <img src={no} alt="Hero" />
          </div>
        </section>


       
        <section className="contest-section" id="contest-section">
  <h2>Live Contests</h2>
  <div className="contest-container">
    {loadingContests ? (
      <p>Loading contests...</p> // Loading message
    ) : contests.length > 0 ? (
      contests.map((contest) => (
        <div
        key={contest.contestId}
        className="contest-card"
        onClick={() => handleCardClick(contest.contestId)} // Fetch link on click
        style={{ cursor: "pointer" }} // Add pointer cursor for better UX
      >
          <div className="contest-header">
            <h3>
              {contest.contestName}
              <span
                className={`contest-difficulty ${contest.complexity.toLowerCase()}`}
              >
                {contest.complexity}
              </span>
            </h3>
          </div>
          <p className="prize-pool">Prize Pool: ₹{contest.prizePool}</p>
          <div className="contest-footer">
            <div className="contest-time-duration">
              <p className="contest-time">Time: {contest.time}</p>
              <p className="contest-duration">Duration: {contest.duration}</p>
            </div>
            <div className="contest-price-box">₹{contest.price}</div>
          </div>
          <p className="app-download-instruction">
            Download app to join the contest
          </p>
        </div>
      ))
    ) : (
      <p>No live contests available at the moment.</p>
    )}
  </div>
</section>



<section className="quiz-categories" id="play-section">
  <h2> Explore our Quiz Categories</h2>
  <div className="categories-container">
    <div className="category-card">
      <img src={atom} alt="Enter Mobile number" />
      <h3>Science</h3>
      <p> Nothing can be  created nothing can be destroyed.</p>
    </div>
    <div className="category-card">
      <img src={vote} alt="Enter OTP" />
      <h3>Politics</h3>
      <p>Politics involves the governance of societies and the creation of laws to manage public affairs and resources</p>
    </div>
    <div className="category-card">
      <img src={ai} alt="Enter Details" />
      <h3>Tech</h3>
      <p>Thinking beyond the boundaries is called technology.</p>
    </div>
    <div className="category-card">
      <img src={math} alt="Contest page" />
      <h3>General Science</h3>
      <p>General Science studies the natural world’s basic principles, including science, and earth sciences,and its governing laws.</p>
    </div>
    <div className="category-card">
      <img src={his} alt="Choose Contest" />
      <h3>History</h3>
      <p>History is the study of past events, people, and societies, providing insight into how the present world has been shaped.</p>
    </div>
  </div>
</section>



<div className="ranking-container">
            <h2>Global Ranking</h2>
            <div className="subheadings">
                <span className="subheading-rank">Rank</span>
                <span className="subheading-name">Name</span>
                <span className="subheading-earnings">Earnings</span>
            </div>
            <div className="ranking-list">
                {topSix.map((user, index) => (
                    <div className="ranking-item top" key={index}>
                        <span className="rank">{index + 1}.</span>
                        <span className="user-name">{user.firstName} {user.lastName}</span>
                        <span className="total-amount">
                        ₹{user.totalAmountWon.toLocaleString()}</span>
                        <Flower className={`flower flower-${index + 1}`} aria-hidden="true" />
                    </div>
                ))}
                <div className="scrollable-section">
                    {remainingUsers.map((user, index) => (
                        <div className="ranking-item" key={index + 6}>
                            <span className="rank">{index + 7}.</span>
                            <span className="user-name">{user.firstName} {user.lastName}</span>
                            <span className="total-amount">
                            ₹{user.totalAmountWon.toLocaleString()}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>



      
 <section className="how-to-play" id="play-section">
  <h2>How to Play</h2>
  <div className="play-container">
    <div className="play-card">
      <img src={enter} alt="Science Image" />
      <h3>Enter Mobile Number</h3>
    </div>
    {/* Google Native Ad */}
    <div className="play-card ad-container">
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1754197679720720"
        crossorigin="anonymous"
      ></script>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-format="fluid"
        data-ad-layout-key="+a+sa-18-54+du"
        data-ad-client="ca-pub-1754197679720720"
        data-ad-slot="4575948562"
      ></ins>
      <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </div>
    <div className="play-card">
      <img src={otp} alt="Science Image" />
      <h3>Enter your OTP</h3>
    </div>
    <div className="play-card">
      <img src={details} alt="Maths Image" />
      <h3>Now enter your details</h3>
    </div>
    {/* Google Native Ad */}
    <div className="play-card ad-container">
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1754197679720720"
        crossorigin="anonymous"
      ></script>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-format="fluid"
        data-ad-layout-key="+a+sa-18-54+du"
        data-ad-client="ca-pub-1754197679720720"
        data-ad-slot="4575948562"
      ></ins>
      <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </div>
    <div className="play-card">
      <img src={contest} alt="Tech Image" />
      <h3>Go to Contest Page</h3>
    </div>
    <div className="play-card">
      <img src={join} alt="English Image" />
      <h3>Choose any contest</h3>
    </div>
    {/* Add more cards and ads as needed */}
  </div>
</section>







<section class="reviews-rating">
  <h2>Reviews & <span class="highlight">Rating</span></h2>
  <div class="reviews-container">
    <div class="review-card">
      <div class="user-info">
        <img src= {pro1} alt="Abhay Gupta" class="profile-img" />
        <h3>Divya Rajput</h3>
      </div>
      <div class="contest-info">
        <p>Sunday Contest Winner</p>
        <p>Techknow Battle</p>
        <p>₹5000 <span>Winnings</span></p>
      </div>
      <p class="review-text">
      Winning the Sunday contest on GuruGuess was an exhilarating experience that filled me with joy and excitement. The thrill of competing and the satisfaction of emerging victorious made it a memorable moment that I look forward to repeating!
      </p>
    </div>

    <div class="review-card">
      <div class="user-info">
        <img src={pro2} alt="Sagar Bhagat" class="profile-img" />
        <h3>Bhavna Sisodia</h3>
      </div>
      <div class="contest-info">
        <p>Mega Contest Winner</p>
        <p>Subject: SCIENCE</p>
        <p> Prize pool: ₹1000 </p>
      </div>
      <p class="review-text">
        One of the many reasons why I trust GuruGuess is because I get my winnings safely and instantly after the contest gets over. I also used my own subjects knowledge to enhance my knowledge in the field of science.
      </p>
    </div>

    <div class="review-card">
      <div class="user-info">
        <img src={pro3} alt="Anuj Yadav" class="profile-img" />
        <h3>Anuj Yadav</h3>
      </div>
      <div class="contest-info">
        <p>Jee Mains Mega Quizz Winner</p>
        <p>BrainWave</p>
        <p>₹1000 <span>Winnings</span></p>
      </div>
      <p class="review-text">
      Participating in the Brainwaves Quiz Contest on GuruGuess was an exhilarating journey from start to finish! As a quiz enthusiast, I was thrilled by the variety of questions and the competitive atmosphere that the platform offered. The user interface was intuitive, making it easy to navigate through the rounds.
      </p>
    </div>
  </div>
  
  <div class="app-rating">
    <p>App Rating</p>
    <span class="stars">⭐ ⭐ ⭐ ⭐ ⭐</span>
    <span class="rating">4.7</span>
    <span class="ratings-count">100+ ratings</span>
  </div>
</section>



      </main>

      {/* Footer Section */}
      <footer className="terms-footer">
        <div className="terms-footer-content">
          <div className="terms-footer-section contact-info">
            <p><FontAwesomeIcon icon={faEnvelope} /> Email: <a href="mailto:guruguesss@gmail.com">guruguesss@gmail.com</a></p>
            <p><FontAwesomeIcon icon={faInstagram} /> Instagram: <a href="https://www.instagram.com/guruguesss" target="_blank" rel="noopener noreferrer">@guruguesss</a></p>
          </div>
          <div className="terms-footer-section">
            <p><FontAwesomeIcon icon={faPhone} /><a href="tel:9140293443">Contact Us 9140293443</a></p>
            <p><FontAwesomeIcon icon={faPhone} /><a href="tel:7460802449">For more queries contact on 7460802449</a></p>
          </div>
        </div>
        <div className="terms-footer-bottom">
       <p> Powered by : KNKN Engeenering Works pvt. ltd.</p>
          <p>© 2024 Guruguess. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
